<template>
  <div class="referral-page">
    <div class="referral container-fluid">
      <div class="container">
        <div class="referral-title">
          <div class="title">
            <span>{{ $t('referral_page.title') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 col-sm-8 partners">
            <div class="partners-top-block">
              <div class="number-partners item">
                <div class="title">
                  <span>{{ $t('referral_page.total') }}</span>
                </div>
                <div class="count-block">
                  <div class="count-value">
                    <span>0</span>
                  </div>
                  <div class="graphic">
                    <img src="./img/line.svg" alt="line" />
                  </div>
                </div>
                <div class="active-block">
                  <span>0 {{ $t('referral_page.active') }}</span>
                </div>
              </div>
              <div class="first-line-partners item">
                <div class="title">
                  <span>{{ $t('referral_page.first') }}</span>
                </div>
                <div class="count-block">
                  <div class="count-value">
                    <span>0</span>
                  </div>
                  <div class="graphic">
                    <img src="./img/line.svg" alt="line" />
                  </div>
                </div>
                <div class="active-block">
                  <span>0 {{ $t('referral_page.active') }}</span>
                </div>
              </div>
            </div>
            <div class="partners-medium-block">
              <div class="partners-header">
                <div class="partners-title">
                  <span>{{ $t('referral_page.wallet') }}</span>
                </div>
                <div class="partners-report">
                  <button>{{ $t('referral_page.report') }}</button>
                </div>
              </div>
              <div class="partners-body">
                <div class="dollar-block">
                  <div class="currency">
                    <span>{{ $t('referral_page.us') }}</span>
                  </div>
                  <div class="value-block">
                    <span>5, 245.36</span>
                  </div>
                </div>
                <div class="profit-block">
                  <div class="value">
                    <img src="./img/ArrowUp.svg" alt="arrow-up" />
                    <span>2.1%</span>
                  </div>
                  <div class="weekday">
                    <span>{{ $t('referral_page.vs') }}</span>
                  </div>
                </div>
                <div class="sales-block">
                  <span>{{ $t('referral_page.sales') }}</span>
                </div>
                <!--Place for graphic-->
                <div class="graphic"></div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 table-box">
            <div class="table-box__header">
              <div class="title">
                <span>{{ $t('referral_page.title_2') }}</span>
              </div>
            </div>
            <div class="table-box__body">
              <table>
                <thead>
                  <tr>
                    <th>{{ $t('referral_page.name') }}</th>
                    <th>{{ $t('referral_page.prestige') }}</th>
                    <th class="prestige">
                      {{ $t('referral_page.prestig') }} 1
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in dataList" :key="i">
                    <td class="item">
                      <div class="ava">
                        <img src="./img/ava.png" alt="ava" />
                      </div>
                      <div class="name">
                        <span>{{ item.name }}</span>
                      </div>
                    </td>
                    <td>{{ item.price }}</td>
                    <td class="prestigePrice">{{ item.prestigePrice }}</td>
                    <td>
                      <button class="view">{{ $t('buttons.views') }}</button>
                    </td>
                    <td>
                      <button class="close">{{ $t('buttons.close') }}</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="referral-footer">
            <b-tabs content-class="mt-3">
              <b-tab title="My partners" active>
                <div class="input-list">
                  <div class="from-date item">
                    <div class="low-title">
                      <span>{{ $t('referral_page.date') }}</span>
                    </div>
                  </div>
                  <div class="to-date item">
                    <div class="low-title">
                      <span>{{ $t('referral_page.to_date') }}</span>
                    </div>
                  </div>
                  <div class="lines item">
                    <div class="low-title">
                      <span>{{ $t('referral_page.lines') }}</span>
                    </div>
                    <select>
                      <option value="1">{{
                        $t('referral_page.all_lines')
                      }}</option>
                      <option value="2">{{ $t('referral_page.lines') }}</option>
                      <option value="3">{{ $t('referral_page.all') }}</option>
                    </select>
                  </div>
                  <div class="search item">
                    <div class="low-title">
                      <span>{{ $t('referral_page.search') }}</span>
                    </div>
                    <div class="search-block">
                      <input type="search" placeholder="Search" />
                      <img src="./img/search.svg" alt="search-icon" />
                    </div>
                  </div>
                  <div class="login item">
                    <div class="low-title">
                      <span>{{ $t('referral_page.title_2') }}</span>
                    </div>
                    <select>
                      <option value="1">{{
                        $t('referral_page.by_login')
                      }}</option>
                      <option value="2">{{ $t('referral_page.by') }}</option>
                      <option value="3">{{ $t('referral_page.login') }}</option>
                    </select>
                  </div>
                </div>
                <div class="table-info">
                  <table>
                    <thead>
                      <tr>
                        <th>{{ $t('referral_page.partners') }}</th>
                        <th>{{ $t('referral_page.totaly') }}</th>
                        <th>{{ $t('referral_page.in_work') }}</th>
                        <th>{{ $t('referral_page.total-ern') }}</th>
                        <th>{{ $t('referral_page.turnovert') }}</th>
                        <th>{{ $t('referral_page.status') }}</th>
                        <th>{{ $t('referral_page.line') }}</th>
                        <th>{{ $t('referral_page.sign') }}</th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                </div>
                <div class="not-found">
                  <span>{{ $t('referral_page.no_partners') }}</span>
                </div>
              </b-tab>
              <b-tab title="Partners accruals"
                ><p>{{ $t('referral_page.second_tab') }}</p></b-tab
              >
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataList: [
        {
          name: 'XMR/BNB',
          price: '$20.00',
          prestigePrice: '$5.00',
        },
        {
          name: 'XMR/BNB',
          price: '$20.00',
          prestigePrice: '$5.00',
        },
        {
          name: 'XMR/BNB',
          price: '$20.00',
          prestigePrice: '$5.00',
        },
        {
          name: 'XMR/BNB',
          price: '$20.00',
          prestigePrice: '$5.00',
        },
        {
          name: 'XMR/BNB',
          price: '$20.00',
          prestigePrice: '$5.00',
        },
        {
          name: 'XMR/BNB',
          price: '$20.00',
          prestigePrice: '$5.00',
        },
        {
          name: 'XMR/BNB',
          price: '$20.00',
          prestigePrice: '$5.00',
        },
        {
          name: 'XMR/BNB',
          price: '$20.00',
          prestigePrice: '$5.00',
        },
        {
          name: 'XMR/BNB',
          price: '$20.00',
          prestigePrice: '$5.00',
        },
      ],
    }
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
